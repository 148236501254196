import { mapActions, mapGetters, mapMutations } from 'vuex'
import dialogView from './dialog-view/index.vue'

export default {
  name: 'setting-course-categories',
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null,
    }
  },
  components: {
    dialogView
  },
  computed: {
    ...mapGetters({
      list: 'settingNiches/list',
      limit: 'settingNiches/limit',
      listLength: 'settingNiches/listLength',
      isLoading: 'settingNiches/isLoading',
      isListLoading: 'settingNiches/isListLoading',
      openDialogView: 'settingNiches/openDialogView',

      checkList: 'settingNiches/checkList'
    }),
    headers () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  created () {
    this.fetchList()
  },
  methods: {
    ...mapActions({
      fetchList: 'settingNiches/GET_LIST',
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES',
      remove: 'settingNiches/DELETE',
      checkItemInCourses: 'settingNiches/CHECK_ITEM_IN_COURSES',
    }),
    ...mapMutations({
      changeSkip: 'settingNiches/CHANGE_SKIP',
      changeDialogView: 'settingNiches/CHANGE_DIALOG_VIEW',
      setList: 'settingNiches/SET_LIST',
      changeEditId: 'settingNiches/CHANGE_EDIT_ID',
      resetCheckList: 'settingNiches/RESET_CHECK_LIST'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialog (status = false, item = null) {
      this.changeEditId(item.id)
      this.changeDialogView(status)
    },
    removeCategory () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },

    changeDialogDeleteItem (item, e) {
        let $btn = e.currentTarget;

        this.isLoadingCheckItem = true;
        $btn.classList.add('has-disabled');

        this.resetCheckList();

        this.checkItemInCourses(item.id).then(() => {
            $btn.classList.remove('has-disabled');
            this.isLoadingCheckItem = false;

            this.showRemoveDialog = true;
            this.removingItem = item;
        })
    }
  },
  destroyed () {
    this.setList([])
  }
}
